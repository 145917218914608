export const BG_PRIMARY = '#142c33';
export const BG_SECONDARY = '#FFFFFF';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = '#95acb2';
export const BG_ALTERNATIVE = '#053A28';
export const TEXT_PRIMARY_LIGHT = BG_SECONDARY;
export const TEXT_PRIMARY = 'white';
export const TEXT_PRIMARY_DARK = BG_SECONDARY_DARK;
export const TEXT_SECONDARY = BG_SECONDARY_TRANSPARENT;
export const BTN_NAVIGATION_COLOR = '#325b67';
export const ICON_NAVIGATION_COLOR = '#fff';
export const DEFAULT_BUTTON_PROPS = {};